import { User } from "../components/CommonData/DataTypes";
import { SET_USER_LOGIN, SET_USER_LOGOUT } from "./actions"


export const setUserLogin = (dispatch: any) => (user: User) => {
  const action = {
    type: SET_USER_LOGIN,
    payload: user,
  }
  dispatch(action);
}

export const setUserLogOut = (dispatch: any) => () => {
  const user: User = {
    userID: 0,
    userIsAuthenticated: false,
    userLogin: "",
    userNic: "",
    userToken: "",
    userPhoneNumber: "",
  }
  
  const action = {
    type: SET_USER_LOGOUT,
    payload: user,
  }
  
  dispatch(action);
}
