import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../CommonData/DataTypes";
import { setUserLogin } from "../../redux/authUser";

export default function MyPhoneNumber(): JSX.Element {
    const dispatch = useDispatch();
    const { userID, userNic, userLogin, userIsAuthenticated, userToken, userPhoneNumber } = useSelector((state: any) => state.user);
    
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [serverMessage, setServerMessage] = useState<string | null>(null);
    const [isServerMessageVisible, setIsServerMessageVisible] = useState<boolean>(false);

    const[isEditMode, setEditMode] = useState<boolean>(false);

    const [phoneNumber, setPhoneNumber] = useState<string>(userPhoneNumber);

    const isValidPhoneNumber = (phone: string): boolean => {
        return /^[+]?\d+$/.test(phone);
      };
      
    const savePhoneNumberOnTheServer = () => {
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        setServerMessage(null);
        setIsServerMessageVisible(false);

        let user: User = { 
            userID: userID,
            userNic: userNic,
            userLogin: userLogin,
            userToken: userToken,
            userIsAuthenticated: userIsAuthenticated,
            userPhoneNumber: userPhoneNumber,
        };

        const request = {
            user_id: userID,
            phone_number: phoneNumber
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' ,
                'Authorization': `Token ${userToken}`,
            },
            body: JSON.stringify(request)
        };

        fetch("https://mitrapro.tech/set_phone_number/", requestOptions)
            .then(async (response) => {
                const data = await response.json();
                if (!response.ok) {
                    setIsServerMessageVisible(true);
                    setServerMessage(data["message"]);
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                //return response.json();
                return data;
            })
            .then(data => {
                setServerMessage(data["message"] as string);
                if (data["success"] as boolean) {
                    localStorage.setItem('userPhoneNumber', phoneNumber);
                    user["userPhoneNumber"] = phoneNumber;

                    setUserLogin (dispatch) (user);
                } else {
                    setIsServerMessageVisible(true);
                }
            })

            .catch(error => {
                setError(error.message || 'Произошла ошибка');
                setIsServerMessageVisible(true);
                setPhoneNumber(userPhoneNumber);
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    };


    const onChangePhoneNumber = (phoneNumber: string) => {
        setPhoneNumber(phoneNumber);
    }

    const handlerClickEditButton = () => {
        setEditMode(true);
    }

    const handlerClickSaveButton = () => {
        savePhoneNumberOnTheServer();
        setEditMode(false);
    }

    const handlerClickCancelButton = () => {
        setEditMode(false);
        setPhoneNumber(userPhoneNumber);
        setServerMessage(null);
        setIsServerMessageVisible(false);
        setError(null);
    }

    const handlerClickDeleteButton = () => {
        setEditMode(false);
    }

    const editButtonJSX: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickEditButton} disabled={isEditMode}>
                <img src={"/edit_button.png"} alt={"E"} />
            </button>
        </>
    
    const saveButtonJSX: JSX.Element = 
        <>
            <button className='escd_button' 
                    onClick={handlerClickSaveButton} 
                    disabled={(!isEditMode || !isValidPhoneNumber(phoneNumber) || loading)}>
                
                {loading ? 
                    <span className="loader"></span> : 
                        <img src={"/save_button.png"} alt={"S"} />
                }
            
            </button>
        </>
    
    const cancelButtonJSX: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickCancelButton} disabled={!isEditMode}>
                <img src={"/cancel_button.png"} alt={"C"} />
            </button>
        </>
    
    const deleteButtonJSX: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickDeleteButton} disabled={!isEditMode}>
                <img src={"/delete_button.png"} alt={"D"} />
            </button>
        </>
    
    const isShowDeleteButton: boolean = false;

    const escdButtonBoxJSX: JSX.Element = 
        <>
            {editButtonJSX}
            {saveButtonJSX}
            {cancelButtonJSX}
            {(isShowDeleteButton) && deleteButtonJSX}
        </>
    
    return (
        <>
            <div className="nickname" >
                <label className="nickname_label" htmlFor="phoneNumberInput">
                        {"Номер телефона:"}
                </label>
                <div className="nickname_input_box">
                    <input
                        className={(isValidPhoneNumber(phoneNumber)) 
                            ? "nickname_input_fld" 
                                : "nickname_input_fld_invalid"}
                        placeholder="Номер телефона"
                        required
                        type="text"
                        name="phoneNumber" 
                        maxLength={16}
                        id="phoneNumberInput"
                        disabled={!isEditMode}
                        autoComplete="off"
                        value={phoneNumber}
                        onChange={(e) => onChangePhoneNumber(e.target.value)}
                    />
                    {escdButtonBoxJSX}
                </div>
            </div>
            {error && <p className="error-message">{error}</p>}
            {isServerMessageVisible && <p className="error-message">{serverMessage}</p>}
        </>
    )
}