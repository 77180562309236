import { useState } from "react";
import { nurseryArr } from "../DebugData/DebugNurseryList";
import NurseryEdit from "./NurseryEdit";
import ObjectList from "../ObjectList";
import { useSelector } from "react-redux";
import { ObjectListTypeCard } from "../CommonData/DataTypes";

export type Nursery = {
    id: number;
    section: number;
    logoNursery: string;
    name: string;
    federation: string;
    logoFrederation:string;
    breeder: string;
    adress: string;
    country: string;
    club: string;
    clubURL: string;
    registrationDate: string;
    registrationNumber: string;
    validDate: string;
}

export default function MyNursery(): JSX.Element {
    const { userID, userNic, userIsAuthenticated } = useSelector((state: any) => state.user);
    
    const urlNursery: string = `https://mitrapro.tech/cat_nursery?user=${userID}`;
    const dataType: ObjectListTypeCard = "CatNursery";

    const noAccessJSX: JSX.Element = 
        <>
            <p>Для получения доступа к своим питомникам необходимо пройти авторизацию, 
                нажмите кнопку Войти в правом верхнем углу экрана.
            </p>
        </>

    return (
        <>  
            {(userIsAuthenticated) ? 
                <ObjectList url={urlNursery} dataType={dataType} 
                    callFrom={"MyNursery"} catStatus={null} /> : noAccessJSX
            }
        </>
    );
}

/*

export default function MyNursery(): JSX.Element {
    function getNurseryCard(id: number): Nursery {
        let item:Nursery = {
            id:0, 
            section: 0, 
            logoNursery:"", 
            name: "",
            federation:"",
            logoFrederation: "",
            breeder: "",
            adress: "",
            country: "",
            club: "",
            clubURL: "",
            registrationDate: "",
            registrationNumber: "",
            validDate: "",
        }
        for (let i=0; i<nurseryArr.length; i++) {
            if (id===nurseryArr[i].id) {
                return nurseryArr[i];
            }
        }
        return item;
    }

    const [showNurseryID, SetNurseryID] = useState(0);

    const handlerClickNursery = (id:number) => {
        SetNurseryID(id);
    }

    const nurseryListJSX = nurseryArr.map((item:Nursery) => 
        <div key={item.id} 
            className="my_nursery_short"
            onClick={() => handlerClickNursery(item.id)}>
            
            <img className="my_nursery_short_img"
                alt="Логотип питомника" 
                src="" />
            
            <div className="my_nursery_short_text">
                <div>{ item.name }</div>
                <div>{ item.federation }</div>
            </div>
        </div>
    );

    return (
        <>      
            { (showNurseryID===0) ? <>{nurseryListJSX}</> :
                <>
                    <div className="go_back" onClick={() => SetNurseryID(0)}>
                        Назад
                    </div>
                    <NurseryEdit nurseryCard={ getNurseryCard(showNurseryID) } />
                 </>
            }
        </>
    );
}

*/