import { useState } from "react";
import ObjectList from "./ObjectList";
import TelephoneNumberHiden from "./TelephoneNumberHiden";
import { NurseryCard, ObjectCatStatus, ObjectListFrom, ObjectListTypeCard } from "./CommonData/DataTypes";
import { useSelector } from "react-redux";

interface CatNurseryCardProps {
    nurseryCard: NurseryCard;
    isReadOnly: boolean;
    callFrom: ObjectListFrom;
}

type NurseryTextTable= {
    name: string,
    value: string | JSX.Element,
    valueEdit: JSX.Element,
}

type SectionElement = {
    id: number,
    name: string,
    isEmpty: boolean,
    url: string,
    dataType: ObjectListTypeCard,
    isAppend: boolean,
    catStatus: ObjectCatStatus | null,
}

export default function CatNurseryFullCard(props: CatNurseryCardProps): JSX.Element {
    const { userToken } = useSelector((state: any) => state.user);
    
    const [activeNurserySection, setActiveNurserySection] = useState<number>(1);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [serverMessage, setServerMessage] = useState<string | null>(null);
    const [isServerMessageVisible, setIsServerMessageVisible] = useState<boolean>(false);

    const [isEditMode, setEditMode] = useState<boolean>(false);

    const [nurseryName, setNurseryName] = useState<string>(props.nurseryCard.name);
    const [nurseryBreederName, setNurseryBreederName] = useState<string>(props.nurseryCard.breeder_name);
    const [nurseryClub, setNurseryClub] = useState<string>(props.nurseryCard.club);
    const [nurseryFederation, setNurseryFederation] = useState<string>(props.nurseryCard.federation);
    const [nurseryLocation, setNurseryLocation] = useState<string>(props.nurseryCard.location);
    const [nurseryDescription, setNurseryDescription] = useState<string>(props.nurseryCard.description);

    const [nurseryNameInitional, setNurseryNameInitional] = useState<string>(props.nurseryCard.name);
    const [nurseryBreederNameInitional, setNurseryBreederNameInitional] = useState<string>(props.nurseryCard.breeder_name);
    const [nurseryClubInitional, setNurseryClubInitional] = useState<string>(props.nurseryCard.club);
    const [nurseryFederationInitional, setNurseryFederationInitional] = useState<string>(props.nurseryCard.federation);
    const [nurseryLocationInitional, setNurseryLocationInitional] = useState<string>(props.nurseryCard.location);
    const [nurseryDescriptionInitional, setNurseryDescriptionInitional] = useState<string>(props.nurseryCard.description);

    const saveDataNurseryOnTheServer = () => {
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        setServerMessage(null);
        setIsServerMessageVisible(false);

        const request = {
            id: props.nurseryCard.id,
            name: nurseryName,
            federation: nurseryFederation,
            breeder_name: nurseryBreederName,
            location: nurseryLocation,
            club: nurseryClub,
            description: nurseryDescription,
        };

        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json' ,
                'Authorization': `Token ${userToken}`,
            },
            body: JSON.stringify(request)
        };

        fetch(`https://mitrapro.tech/cat_nursery/${props.nurseryCard.id}/`, requestOptions)
            .then(async (response) => {
                const data = await response.json();
                if (!response.ok) {
                    setIsServerMessageVisible(true);
                    setServerMessage(data["detail"]);
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return data;
            })
            .then(data => {
                setNurseryName(data["name"]);
                setNurseryBreederName(data["breeder_name"]);
                setNurseryClub(data["club"]);
                setNurseryFederation(data["federation"]);
                setNurseryLocation(data["location"]);
                setNurseryDescription(data["description"]);
            })

            .catch(error => {
                setError(error.message || 'Произошла ошибка');
                setIsServerMessageVisible(true);
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    };
    
    const handlerClickEditButton = () => {
        setEditMode(true);
        setActiveNurserySection(0);

        setServerMessage(null);
        setIsServerMessageVisible(false);

        setNurseryNameInitional(nurseryName);
        setNurseryBreederNameInitional(nurseryBreederName);
        setNurseryClubInitional(nurseryClub);
        setNurseryFederationInitional(nurseryFederation);
        setNurseryLocationInitional(nurseryLocation);
        setNurseryDescriptionInitional(nurseryDescription);
        
    }

    const handlerClickSaveButton = () => {
        //saveNickNameOnTheServer();
        setEditMode(false);
        if (isChange()) saveDataNurseryOnTheServer();
    }

    const handlerClickCancelButton = () => {
        setEditMode(false);
        
        setServerMessage(null);
        setIsServerMessageVisible(false);
        setError(null);

        setNurseryName(nurseryNameInitional);
        setNurseryBreederName(nurseryBreederNameInitional);
        setNurseryClub(nurseryClubInitional);
        setNurseryFederation(nurseryFederationInitional);
        setNurseryLocation(nurseryLocationInitional);
        setNurseryDescription(nurseryDescriptionInitional);        
    }

    const handlerClickDeleteButton = () => {
        setEditMode(false);
    }

    const handlerClickAppendButton = () => {

    }

    const editButtonJSX: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickEditButton} disabled={isEditMode}>
                <img src={"/edit_button.png"} alt={"E"} />
            </button>
        </>
    
    const saveButtonJSX: JSX.Element = 
        <>
            <button className='escd_button' 
                onClick={handlerClickSaveButton} 
                disabled={(!isEditMode || loading)}>
                
                {loading ? 
                    <span className="loader"></span> : 
                        <img src={"/save_button.png"} alt={"S"} />
                }
            
            </button>
        </>
    
    const cancelButtonJSX: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickCancelButton} disabled={!isEditMode}>
                <img src={"/cancel_button.png"} alt={"C"} />
            </button>
        </>
    
    const deleteButtonJSX: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickDeleteButton} disabled={!isEditMode}>
                <img src={"/delete_record_red_button.png"} alt={"D"} />
            </button>
        </>
    
    const appendButtonJSX: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickAppendButton} disabled={false}>
                <img src={"/append_button.png"} alt={"D"} />
            </button>
        </>
    
    const isShowDeleteButton: boolean = false;
    //const isShowAppendButton: boolean = (props.callFrom === "MyNursery" );

    const escdButtonBoxJSX: JSX.Element = 
        <>
            {editButtonJSX}
            {saveButtonJSX}
            {cancelButtonJSX}
            {(isShowDeleteButton) && deleteButtonJSX}
        </>

    const isChange = (): boolean => {
        return (
            nurseryName !== nurseryNameInitional || 
            nurseryBreederName !== nurseryBreederNameInitional ||
            nurseryClub !== nurseryClubInitional || 
            nurseryFederation !== nurseryFederationInitional ||
            nurseryLocation !== nurseryLocationInitional || 
            nurseryDescription !== nurseryDescriptionInitional
        );
    }
    
    const handlerClick = (id: number, event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation(); // Останавливаем всплытие
        setActiveNurserySection(id);
    };

    const nurseryNameInputJSX: JSX.Element = 
        <>
            <input
                className={"name_nursery_input_fld"}
                placeholder="Наименование"
                required
                type="text"
                name="nurseryName" 
                maxLength={64}
                id="nurseryNameInput"
                autoComplete="on"
                value={nurseryName}
                onChange={(e) => setNurseryName(e.target.value)}
            />
        </>

    const nurseryBreederNameJSX: JSX.Element = 
        <>
            <div className="nickname_input_box">
                <input
                    className={"nickname_input_fld"}
                    placeholder="Имя"
                    required
                    type="text"
                    name="nurseryBreederName" 
                    maxLength={48}
                    id="nurseryBreederName"
                    autoComplete="on"
                    value={nurseryBreederName}
                    onChange={(e) => setNurseryBreederName(e.target.value)}
                />
            </div>
        </>

    const nurseryClubInputJSX: JSX.Element = 
        <>
            <div className="nickname_input_box">
                <input
                    className={"nickname_input_fld"}
                    placeholder="Клуб"
                    required
                    type="text"
                    name="nurseryClub" 
                    maxLength={32}
                    id="nurseryClubInput"
                    autoComplete="on"
                    value={nurseryClub}
                    onChange={(e) => setNurseryClub(e.target.value)}
                />
            </div>
        </>
    
    const nurseryFederationInputJSX: JSX.Element = 
        <>
            <div className="nickname_input_box">
                <input
                    className={"nickname_input_fld"}
                    placeholder="Система"
                    required
                    type="text"
                    name="nurseryFederation" 
                    maxLength={8}
                    id="nurseryFederationInput"
                    autoComplete="on"
                    value={nurseryFederation}
                    onChange={(e) => setNurseryFederation(e.target.value)}
                />
            </div>
        </>
    
    const nurseryLocationInputJSX: JSX.Element = 
        <>
            <div className="nickname_input_box">
                <input
                    className={"nickname_input_fld"}
                    placeholder="Город"
                    required
                    type="text"
                    name="nurseryLocation" 
                    maxLength={8}
                    id="nurseryLocationInput"
                    autoComplete="on"
                    value={nurseryLocation}
                    onChange={(e) => setNurseryLocation(e.target.value)}
                />
            </div>
        </>
    
    const nurseryDescriptionInputJSX: JSX.Element = 
        <>
            <textarea
                className={"description_input_fld"}
                placeholder="Описание"
                required
                name="nurseryDescription" 
                maxLength={256}
                id="nurseryDescriptionInput"
                autoComplete="on"
                value={nurseryDescription}
                onChange={(e) => setNurseryDescription(e.target.value)}
            />
            
        </>
    const nurserySectionArray: SectionElement[] = [
        {
            id: 1,
            name: "Котята",
            isEmpty: false,
            url: `https://mitrapro.tech/cat_nurseries/${props.nurseryCard.id}/KITTEN/cats`,
            dataType: "CatCard",
            isAppend: true,
            catStatus: "KITTEN",
        },
        {
            id: 2,
            name: "Производители",
            isEmpty: false,
            url: `https://mitrapro.tech/cat_nurseries/${props.nurseryCard.id}/BREEDING/cats`,
            dataType: "CatCard",
            isAppend: true,
            catStatus: "BREEDING",
        },
        {
            id: 3,
            name: "Ньютеры",
            isEmpty: false,
            url: `https://mitrapro.tech/cat_nurseries/${props.nurseryCard.id}/NEUTER/cats`,
            dataType: "CatCard",
            isAppend: true,
            catStatus: "NEUTER"
        },
        {
            id: 4,
            name: "Ветераны",
            isEmpty: false,
            url: `https://mitrapro.tech/cat_nurseries/${props.nurseryCard.id}/VETERAN/cats`,
            dataType: "CatCard",
            isAppend: true,
            catStatus: "VETERAN"
        },
        {
            id: 5,
            name: "Выпускники",
            isEmpty: false,
            url: `https://mitrapro.tech/cat_nurseries/${props.nurseryCard.id}/GRADUATE/cats`,
            dataType: "CatCard",
            isAppend: true,
            catStatus: "GRADUATE"
        },
        {
            id: 6,
            name: "Достижения",
            isEmpty: false,
            url: `https://mitrapro.tech/cat_nurseries/${props.nurseryCard.id}/achievement-photos/`,
            dataType: "CatPhoto",
            isAppend: false,
            catStatus: null,
        },
        {
            id: 7,
            name: "Фото",
            isEmpty: false,
            url: `https://mitrapro.tech/cat_nurseries/${props.nurseryCard.id}/photos/`,
            dataType: "CatPhoto",
            isAppend: false,
            catStatus: null,
        }, 
    ]

    const nurserySectionJSX = nurserySectionArray.map((item) =>
        (!item.isEmpty) &&
            <>
                <div className="nursery_section" key={item.id}  onClick= {(event) => handlerClick((activeNurserySection === item.id) ? 0 : item.id, event)}>
                    <div className={(activeNurserySection === item.id) ? "nursery_section_head_active" : "nursery_section_head"}>
                        <h2>{item.name}</h2>
                    </div>
                    <div className={(activeNurserySection === item.id) ? "nursery_section_button_active" : "nursery_section_button"}>
                        <h2>{(activeNurserySection === item.id) ?  "\xD7" : "\x2B"}</h2>
                    </div>
                </div>
                {(activeNurserySection === item.id) ? 
                    <div className="nursery_section_card">
                        <ObjectList url={item.url} dataType={item.dataType} 
                            callFrom={props.callFrom} catStatus={item.catStatus} />
                    </div> : <></>
                }
            </>
    );

    const data: NurseryTextTable[] = [
        {
            name: 'Заводчик: ',
            value: nurseryBreederName,
            valueEdit: nurseryBreederNameJSX
        },
        {
            name: 'Клуб: ',
            value: nurseryClub,
            valueEdit: nurseryClubInputJSX
        },
        {
            name: 'Фелинологическая система: ',
            value: nurseryFederation,
            valueEdit: nurseryFederationInputJSX
        },
        {
            name: 'Город: ',
            value: nurseryLocation,
            valueEdit: nurseryLocationInputJSX
        },
        {
            name: 'Телефон: ',
            value: <TelephoneNumberHiden telNum={props.nurseryCard.phone_number || ''} telNumHiden={"+Х-ХXX-XXX-XX-XX"} className={"short_card_telephone_number"} />,
            valueEdit: <TelephoneNumberHiden telNum={props.nurseryCard.phone_number || ''} telNumHiden={"+Х-ХXX-XXX-XX-XX"} className={"short_card_telephone_number"} />,
        },
    ]

    const tableText = data.map((item, index) => (
        (item.value !== '') ?
            <tr className="row" key={index}>
                <td className="cell1">{item.name}</td>
                <td className="cell2">{(isEditMode) ? item.valueEdit : item.value}</td>
            </tr> : <></>
    ));

    const nurseryTableInformJSX: JSX.Element =
        <>
            <div className="nursery_info">
                <table className="styled-table">
                    {tableText}
                </table>
            </div>
        </>

    const nurseryHeadJSX: JSX.Element = 
        <>
            <h1>Питомник {(isEditMode) ? nurseryNameInputJSX : nurseryName}</h1>
            <p>{(isEditMode) ? nurseryDescriptionInputJSX : nurseryDescription}</p>
            {nurseryTableInformJSX}
            {isServerMessageVisible && <p className="error-message">{serverMessage}</p>}
        </>
    
    const nurseryFullCardJSX: JSX.Element = 
        <>
            <div className="nursery_box">
                {nurseryHeadJSX}
                {nurserySectionJSX}
            </div>
        </>
    
    return (
        <>
            {(props.callFrom === "MyNursery") && escdButtonBoxJSX}
            {nurseryFullCardJSX}
        </>
    );
}

//{(isShowAppendButton && item.isAppend) && appendButtonJSX}