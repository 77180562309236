import { useSelector } from "react-redux";
import NickName from "./NickName";
import MyPhoneNumber from "./MyPhoneNumber";

export default function MyProfile(): JSX.Element {
    const { userID, userNic, userIsAuthenticated } = useSelector((state: any) => state.user);
    
    return (
        <>      
            {(userIsAuthenticated) ? 
                <div className="profile">
                    <h2>Профиль</h2>
                    <NickName />
                    <MyPhoneNumber />
                </div> : 
                    <>
                        <p>Для получения доступа к своему профилю 
                            необходимо пройти авторизацию, 
                            нажмите кнопку Войти в правом верхнем углу экрана.</p>
                    </>
            }
        </>
    );
}