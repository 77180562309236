import PetShortCard from "./PetShortCard";
import Advertiser from "./Advertiser";
import { useEffect, useState } from "react";
import PetFullCardPage from "./PetFullCardText";
import { useSelector } from "react-redux";
import { advertiserList } from "./DebugData/DebugAdvertiserList";
import TelephoneNumberHiden from "./TelephoneNumberHiden";
import PetFullCardText from "./PetFullCardText";
import ObjectList from "./ObjectList";
import { ObjectListTypeCard } from "./CommonData/DataTypes";

export type Photo = {
    id: number,
    src: string,
    alt: string,
}

export interface Card {
    sex: 'MALE' | 'FEMALE';
    id: number;
    name: string;
    breed: string;
    dob: string; 
    price: string;
    photos: Array<Photo>;
    description: string;
    telNumber: string;
}

export interface Advert {
    id: number;
    name: string;
    url: string;
}

interface ItemShowObject {
        kind: string;
        id: number;
    }

export default function HomePage(): JSX.Element {
    const {sectionID} = useSelector((state: any) => state.section);
    const { userIsAuthenticated } = useSelector((state:any) => state.user);

    const urlAdvertiser: string = 'https://mitrapro.tech/cats_withphoto?is_show_advertiser=true';
    const dataType: ObjectListTypeCard = "CatCard";

    return (
        <>
            {sectionID===1 && <ObjectList url={urlAdvertiser} dataType={dataType} 
                                    callFrom={"HomePage"} catStatus={null} />}    
        </>
    );
}

