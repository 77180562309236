export default function Logo(): JSX.Element {
    return (
        <>
            <div className="section_logo">
                <div className="logo_block">
                    <img src="/logo_petbreeder.png" alt="Лого" />
                </div>
            </div>
        </>
    );
}