import { ObjectCatStatus } from "./DataTypes";

export const baseUrl: string = "http://localhost:8000"
//export const baseUrl: string = "https://mitrapro.tech"

export const paginationServer: number = 20;

type CatStatusDict = {
    en: ObjectCatStatus,
    rus: string,
}

export const catStatusDictionary: CatStatusDict[] = [
    { en: "BREEDING", rus: "Племенное животное" },
    { en: "NEUTER", rus: "Ньютер" },
    { en: "VETERAN", rus: "Ветеран" },
    { en: "KITTEN", rus: "Котёнок" },
    { en: "GRADUATE", rus: "Выпускник" },
];
