import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NurseryCard } from "./CommonData/DataTypes";
import CatNurseryFullCard from "./CatNurseryFullCard";

export default function NurseryOnlyThis(): JSX.Element {
    //alert("Hello");
    const emptyCard: NurseryCard = {
        id: 0,
        user: 0,
        name: "",
        federation: "",
        breeder_name: "",
        location: "",
        club: "",
        description: "",
        phone_number: ""
    };

    const { id } = useParams<{ id: string }>();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [nurseryCard, setNurseryCard] = useState<NurseryCard>(emptyCard);

    const navigate = useNavigate();

    const getNursery = () => {
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch(`https://mitrapro.tech/cat_nursery/${id}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return response.json();
            })
            .then(data => {
                //alert(data["name"]);
                const recivedNurseryCard: NurseryCard = {
                    id: data["id"],
                    user:data["user"],
                    name: data["name"],
                    federation: data["federation"],
                    breeder_name: data["breeder_name"],
                    location: data["location"],
                    club: data["club"],
                    description: data["description"],
                    phone_number: data["phone_number"],
                }
                setNurseryCard(recivedNurseryCard);
            })

            .catch(error => {
                setError(error.message || 'Произошла ошибка');
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });

    }
    
    useEffect(() => {
        //alert(`id=${id}`);
        if (id && !isNaN(Number(id))) {
            if (Number(id) > 0) {
                getNursery();
            }
        }
    }, []);

    const handlerClose = () => {
        navigate("/")
    }
    
    const nurseryWindowJSX: JSX.Element = 
        <>
            <div className="nursery_window_overlay">
                <div className="nursery_window_content_common">
                    <button className="full_card_close_button"
                        onClick={() => handlerClose()}
                    >
                        <img src="/close_button.png" alt="Left"/>
                    </button>

                    <CatNurseryFullCard nurseryCard={nurseryCard} isReadOnly={true} callFrom={"NurseryOnlyThis"} />

                </div>
            </div>
        </>
    
    const loaderJSX : JSX.Element =
    <>
        <div className="loader_overlay">
            <span className="loader_orange"></span>
        </div>
    </>

const errorJSX: JSX.Element =
    <>
        <div className="error_overlay">
            <div className="error_box">
                <div className="error_text">
                    {error}
                </div>
                <button className="full_card_close_button"
                    onClick={() => handlerClose()}
                >
                        <img src="/close_button.png" alt="Left"/>
                </button>
            </div>

        </div>
    </>
    
    return (
        <>
            {(!id || isNaN(Number(id))) && <h2>Ошибка: неверный формат ID</h2>}
            {(Number(id) < 0) && <h2>Ошибка: недопустимое значение ID</h2>}
            {(nurseryCard.id > 0) && nurseryWindowJSX}
            {loading && loaderJSX}
            {error && errorJSX}
        </>
    );
}

//{(id) && nurseryWindowJSX}

//<CatNurseryFullCard nurseryCard={nurseryCard} />

//<CatNurseryFullCard nurseryCard={objectArray[showObjectWindow] as NurseryCard} />