import { Link } from "react-router-dom";

export default function Page404(): JSX.Element {
    return (
        <>      
        <div className="page404">
            <div>
                <img alt={"page404"} src={"/page404.png"} />
                <p>СТРАНИЦА НЕ НАЙДЕНА</p>
                <Link to="/">Вернуться на главную</Link>
            </div>
        </div>
        </> 
    );
}