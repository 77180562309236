import { useState } from "react";
import TelephoneNumberHiden from "./TelephoneNumberHiden";
import { CatCard, CatPhoto, DeleteButtonProps, ObjectCatStatus, ObjectListFrom, Parameter } from "./CommonData/DataTypes";
import { formatDate, formatPrice, getSex, getStringValue } from "./lib/FormatData";

interface CatFullCardProps {
    catCard: CatCard;
    callFrom: ObjectListFrom;
}

export default function CatFullCard(props: CatFullCardProps): JSX.Element {
    const [activePhoto, setActivePhoto] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [serverMessage, setServerMessage] = useState<string | null>(null);
    const [isServerMessageVisible, setIsServerMessageVisible] = useState<boolean>(false);

    const[isEditModeText, setEditModeText] = useState<boolean>(false);
    const[isEditModePhoto, setEditModePhoto] = useState<boolean>(false);

    const [catPhotos, setCatPhotos] = useState<Array<CatPhoto>>(props.catCard.photos);

    const [catID, setCatID] = useState<number>(props.catCard.id);
    const [catNursery, setCatNursery] = useState<number>(props.catCard.cat_nursery);
    const [catStatus, setCatStatus] = useState<ObjectCatStatus | null>(props.catCard.cat_status);
    const [catBreed, setCatBreed] = useState<string>(props.catCard.breed);
    const [catName, setCatName] = useState<string>(props.catCard.name);
    const [catSirID, setCatSirID] = useState<number>(props.catCard.sir_id);
    const [catSirName, setCatSirName] = useState<string>(props.catCard.sir_name);
    const [catSirCoat, setCatSirCoat] = useState<string>(props.catCard.sir_coat);
    const [catDamID, setCatDamID] = useState<number>(props.catCard.dam_id);
    const [catDamName, setCatDamName] = useState<string>(props.catCard.dam_name);
    const [catDamCoat, setCatDamCoat] = useState<string>(props.catCard.dam_coat);
    const [catBirthday, setCatBirthday] = useState<string | null>(props.catCard.birthday);
    const [catPetPrice, setCatPetPrice] = useState<string>(props.catCard.pet_price);
    const [catBreedPrice, setCatBreedPrice] = useState<string>(props.catCard.breed_price);
    const [catCoat, setCatCoat] = useState<string>(props.catCard.coat);
    const [catSex, setCatSex] = useState<string>(props.catCard.sex);
    const [catSaleStatus, setCatSaleStatus] = useState<string>(props.catCard.sale_status);
    const [catIsNeuter, setCatIsNeuter] = useState<boolean>(props.catCard.is_neuter);
    const [catAdvText, setCatAdvText] = useState<string>(props.catCard.adv_text);

    const [catIDInit, setCatIDInit] = useState<number>(props.catCard.id);
    const [catNurseryInit, setCatNurseryInit] = useState<number>(props.catCard.cat_nursery);
    const [catStatusInit, setCatStatusInit] = useState<ObjectCatStatus | null>(props.catCard.cat_status);
    const [catBreedInit, setCatBreedInit] = useState<string>(props.catCard.breed);
    const [catNameInit, setCatNameInit] = useState<string>(props.catCard.name);
    const [catSirIDInit, setCatSirIDInit] = useState<number>(props.catCard.sir_id);
    const [catSirNameInit, setCatSirNameInit] = useState<string>(props.catCard.sir_name);
    const [catSirCoatInit, setCatSirCoatInit] = useState<string>(props.catCard.sir_coat);
    const [catDamIDInit, setCatDamIDInit] = useState<number>(props.catCard.dam_id);
    const [catDamNameInit, setCatDamNameInit] = useState<string>(props.catCard.dam_name);
    const [catDamCoatInit, setCatDamCoatInit] = useState<string>(props.catCard.dam_coat);
    const [catBirthdayInit, setCatBirthdayInit] = useState<string | null>(props.catCard.birthday);
    const [catPetPriceInit, setCatPetPriceInit] = useState<string>(props.catCard.pet_price);
    const [catBreedPriceInit, setCatBreedPriceInit] = useState<string>(props.catCard.breed_price);
    const [catCoatInit, setCatCoatInit] = useState<string>(props.catCard.coat);
    const [catSexInit, setCatSexInit] = useState<string>(props.catCard.sex);
    const [catSaleStatusInit, setCatSaleStatusInit] = useState<string>(props.catCard.sale_status);
    const [catIsNeuterInit, setCatIsNeuterInit] = useState<boolean>(props.catCard.is_neuter);
    const [catAdvTextInit, setCatAdvTextInit] = useState<string>(props.catCard.adv_text);

    const [photoID, setPhotoID] = useState<number | null | undefined>(props.catCard.photos?.[0]?.id);
    const [photoCat, setPhotoCat] = useState<number | null | undefined>(props.catCard.photos?.[0]?.cat);
    const [photoPathFile, setPhotoPathFile] = useState<string | null | undefined>(props.catCard.photos?.[0]?.path_file);
    const [photoDate, setPhotoDate] = useState<string | null | undefined>(props.catCard.photos?.[0]?.date);
    const [photoTitle, setPhotoTitle] = useState<string | null | undefined>(props.catCard.photos?.[0]?.title);
    const [photoDescription, setPhotoDescription] = useState<string | null | undefined>(props.catCard.photos?.[0]?.description);
    const [photoIsShowAchivement, setPhotoIsShowAchivement] = useState<boolean | null | undefined>(props.catCard.photos?.[0]?.is_show_achivement);
    const [photoIsShowPhoto, setPhotoIsShowPhoto] = useState<boolean | null | undefined>(props.catCard.photos?.[0]?.is_show_photo);

    const [photoIDInit, setPhotoIDInit] = useState<number | null | undefined>(props.catCard.photos?.[0]?.id);
    const [photoCatInit, setPhotoCatInit] = useState<number | null | undefined>(props.catCard.photos?.[0]?.cat);
    const [photoPathFileInit, setPhotoPathFileInit] = useState<string | null | undefined>(props.catCard.photos?.[0]?.path_file);
    const [photoDateInit, setPhotoDateInit] = useState<string | null | undefined>(props.catCard.photos?.[0]?.date);
    const [photoTitleInit, setPhotoTitleInit] = useState<string | null | undefined>(props.catCard.photos?.[0]?.title);
    const [photoDescriptionInit, setPhotoDescriptionInit] = useState<string | null | undefined>(props.catCard.photos?.[0]?.description);
    const [photoIsShowAchivementInit, setPhotoIsShowAchivementInit] = useState<boolean | null | undefined>(props.catCard.photos?.[0]?.is_show_achivement);
    const [photoIsShowPhotoInit, setPhotoIsShowPhotoInit] = useState<boolean | null | undefined>(props.catCard.photos?.[0]?.is_show_photo);

    function getStatus(statusEng: string): string {
        let strStatus = ""

        const dict = [
            { en: "SALE",               rus: "Продаётся" },
            { en: "SOLD",               rus: "Продан" },
            { en: "RESERVED",           rus: "Зарезервирован"},
            { en: "NOT_SALE",           rus: "Не для продажи"},
            { en: "INTO_GOOD_HANDS",    rus: "В добрые руки"}
        ];

        for (let i = 0; i < dict.length; i++) {
            if (statusEng === dict[i].en) {
                strStatus = dict[i].rus
                break
            }
        }
        
        return strStatus;
    }

    function isPhotoComment(arr: Parameter[]): boolean {
        let result: boolean = false;
        for (let i = 1; i < arr.length; i++) {
            if (typeof arr[i].value === 'string') {
                if (arr[i].value !== '') {
                    result = true;
                    break;
                }
            }
            
        }
        return result;
    }

    function isPrice(price: string): boolean {
        const numericValue = parseFloat(price);
        if (isNaN(numericValue) || numericValue === 0) {
            return false;
        } else {
            return true;
        }
    }

    const catNameInputJSX: JSX.Element = 
        <>
            <input
                className={"cat_input_fld"}
                placeholder="Кличка"
                required
                type="text"
                name="catName" 
                maxLength={64}
                id="catNameInput"
                autoComplete="on"
                value={catName}
                onChange={(e) => setCatName(e.target.value)}
            />
        </>
    
    const catBreedInputJSX: JSX.Element = 
        <>
            <input
                className={"cat_input_fld"}
                placeholder="Порода"
                required
                type="text"
                name="catBreed" 
                maxLength={16}
                id="catBreedInput"
                autoComplete="on"
                value={catBreed}
                onChange={(e) => setCatBirthday(e.target.value)}
            />
        </>
    
    const catSirNameJSX: JSX.Element = <></>
    const catSirCoatJSX: JSX.Element = <></>
    const catDamNameJSX: JSX.Element = <></>
    const catDamCoatJSX: JSX.Element = <></>

    const catCoatInputJSX: JSX.Element = 
        <>
            <input
                className={"cat_input_fld"}
                placeholder="Окрас"
                required
                type="text"
                name="catCoad" 
                maxLength={16}
                id="catCoatInput"
                autoComplete="on"
                value={catCoat}
                onChange={(e) => setCatCoat(e.target.value)}
            />  
        </>
    
    const catSexInputJSX: JSX.Element = 
        <>
            <input
                className={"cat_input_fld"}
                placeholder="Пол"
                required
                type="text"
                name="catSex" 
                maxLength={8}
                id="catSexInput"
                autoComplete="on"
                value={catSex}
                onChange={(e) => setCatSex(e.target.value)}
            />  
        </>
    
    const catBirthdayInputJSX: JSX.Element = 
        <>
            <input
                className={"cat_input_fld"}
                placeholder="Дата рождения"
                required
                type="text"
                name="catBirthday" 
                maxLength={10}
                id="catBirthdayInput"
                autoComplete="on"
                value={catBirthday || ''}
                onChange={(e) => setCatBirthday(e.target.value)}
            />  
        </>
    
    const catPetPriceInputJSX: JSX.Element = 
        <>
            <input
                className={"cat_input_fld"}
                placeholder="Цена в любимцы"
                required
                type="number"
                name="catPetPriceInput" 
                maxLength={12}
                id="catPetPriceInput"
                autoComplete="on"
                value={catPetPrice}
                onChange={(e) => setCatPetPrice(e.target.value)}
            />  
        </>
    
    const catBreedPriceInputJSX: JSX.Element = 
        <>
            <input
                className={"cat_input_fld"}
                placeholder="Цена для племенной работы"
                required
                type="number"
                name="catBreedPriceInput" 
                maxLength={12}
                id="catBreedPriceInput"
                autoComplete="on"
                value={catBreedPrice}
                onChange={(e) => setCatBreedPrice(e.target.value)}
            />  
        </>
    
    const catSaleStatusInputJSX: JSX.Element = 
        <>
            <input
                className={"cat_input_fld"}
                placeholder="Статус"
                required
                type="text"
                name="catSaleStatus" 
                maxLength={8}
                id="catSaleStatusInput"
                autoComplete="on"
                value={catSaleStatus}
                onChange={(e) => setCatSaleStatus(e.target.value)}
            />  
        </>
    
    const catAdvTextInputJSX: JSX.Element = 
        <>
            <textarea
                className={"cat_description_input_fld"}
                placeholder="Описание"
                required
                name="catAdvText" 
                maxLength={256}
                id="catAdvTextInput"
                autoComplete="on"
                value={catAdvText}
                onChange={(e) => setCatAdvText(e.target.value)}
            />
            
        </>

    const data: Parameter[] = [
        { 
            isActive: true,
            name: "Кличка",
            value: getStringValue(catName),
            valueEdit: catNameInputJSX,
        },
        { 
            isActive: true,
            name: "Порода",
            value: getStringValue(catBreed),
            valueEdit: catBreedInputJSX,
        },
        {
            isActive: true,
            name: "Окрас",
            value: getStringValue(catCoat),
            valueEdit: catCoatInputJSX,
        },
        {
            isActive: true,
            name: "Пол",
            value: getSex(getStringValue(catSex), catIsNeuter),
            valueEdit: catSexInputJSX,
        },
        {
            isActive: true,
            name: "Дата рождения",
            value: formatDate(getStringValue(catBirthday)),
            valueEdit: catBirthdayInputJSX,
        },
        {
            isActive: false,
            name: "Отец",
            value: getStringValue(catSirName),
            valueEdit: catSirNameJSX,
        },
        {
            isActive: false,
            name: "Окрас отца",
            value: getStringValue(catSirCoat),
            valueEdit: catSirCoatJSX,
        },
        {
            isActive: false,
            name: "Мать",
            value: getStringValue(catDamName),
            valueEdit: catDamNameJSX,
        },
        {
            isActive: false,
            name: "Окрас матери",
            value: getStringValue(catDamCoat),
            valueEdit: catDamCoatJSX,
        },
        {
            isActive: true,
            name: "Цена в любимцы",
            value: (isPrice(catPetPrice)) ? formatPrice(getStringValue(catPetPrice)) : "",
            valueEdit: catPetPriceInputJSX,
        },
        {
            isActive: true,
            name: "Цена для племенной работы",
            value: (isPrice(catBreedPrice)) ? formatPrice(getStringValue(catBreedPrice)) : "",
            valueEdit: catBreedPriceInputJSX,
        },
        {
            isActive: true,
            name: "Статус",
            value: getStatus(getStringValue(catSaleStatus)),
            valueEdit: catSaleStatusInputJSX,
        },
        {
            isActive: true,
            name: "Описание",
            value: getStringValue(catAdvText),
            valueEdit: catAdvTextInputJSX
        },
    ]

    const photoDateInputJSX: JSX.Element = 
        <>
            <input
                className={"cat_input_fld"}
                placeholder="Дата фото"
                required
                type="text"
                name="photoDate" 
                maxLength={10}
                id="photoDateInput"
                autoComplete="on"
                value={photoDate || ''}
                onChange={(e) => setPhotoDate(e.target.value)}
            />  
        </>
    
    const photoTitleInputJSX: JSX.Element = 
        <>
            <input
                className={"cat_input_fld"}
                placeholder="Заголовок фото"
                required
                type="text"
                name="photoTitle" 
                maxLength={128}
                id="photoTitleInput"
                autoComplete="on"
                value={photoTitle || ''}
                onChange={(e) => setPhotoTitle(e.target.value)}
            />  
        </>
    
    const photoDescriptionInputJSX: JSX.Element = 
        <>
            <textarea
                className={"cat_description_input_fld"}
                placeholder="Описание"
                required
                name="photoDescription" 
                maxLength={256}
                id="photoDescriptionInput"
                autoComplete="on"
                value={photoDescription || ''}
                onChange={(e) => setPhotoDescription(e.target.value)}
            />
            
        </>
    
    const photoUploadJSX: JSX.Element = <></>

    const dataPhoto: Parameter[] = [
        {
            isActive: true,
            name: "Фото",
            value: (!catPhotos || catPhotos.length === 0) ? "" : " ",
            valueEdit: photoUploadJSX,
        },
        {
            isActive: true,
            name: "Дата фото",
            value: (!catPhotos || catPhotos.length === 0) ? "" : formatDate(getStringValue(catPhotos[activePhoto].date), false),
            valueEdit: photoDateInputJSX,
        },
        {
            isActive: true,
            name: "Заголовок",
            value: (!catPhotos || catPhotos.length === 0) ? "" : getStringValue(catPhotos[activePhoto].title),
            valueEdit: photoTitleInputJSX,
        },
        {
            isActive: true,
            name: "Описание",
            value: (!catPhotos || catPhotos.length === 0) ? "" : getStringValue(catPhotos[activePhoto].description),
            valueEdit: photoDescriptionInputJSX,
        },
    ]

    const cardText = data.map((item, index) => (
        (item.isActive) ?
            <tr className="row" key={index}>
                <td className="cell1">{item.name}</td>
                <td className="cell2">{(isEditModeText) ? item.valueEdit : item.value}</td>
            </tr> : <></>
    ));

    const cardPhotoText = dataPhoto.map((item, index) => (
        (item.isActive) ?
            <tr className="row" key={index}>
                <td className="cell1">{item.name}</td>
                <td className="cell2">{(isEditModePhoto) ? item.valueEdit : item.value}</td>
            </tr> : <></>
    ));

    const handlerPrevPhoto = () => {
        if (activePhoto > 0) {
            setActivePhoto(activePhoto - 1);
        }
    }

    const handlerNextPhoto = () => {
        if (activePhoto < props.catCard.photos.length - 1) {
            setActivePhoto(activePhoto + 1);
        }
    }

    const sliderButtonsJSX = (!catPhotos || catPhotos.length === 0) ? <></> : 
        catPhotos.map((item: CatPhoto, index: number) =>
        <>
            <div key={index} 
                className={(activePhoto===index) ? "photo_slider_item_active" : "photo_slider_item"} 
                onClick={() => setActivePhoto(index)}
            >
            </div>
        </>
    );

    const handlerClickEditButtonText = () => {
        setEditModeText(true);

        setCatNurseryInit(catNursery);
        setCatStatusInit(catStatus);
        setCatBreedInit(catBreed);
        setCatNameInit(catName);
        setCatSirIDInit(catSirID);
        setCatSirNameInit(catSirName);
        setCatSirCoatInit(catSirCoat);
        setCatDamIDInit(catDamID);
        setCatDamNameInit(catDamName);
        setCatDamCoatInit(catDamCoat);
        setCatBirthdayInit(catBirthday);
        setCatPetPriceInit(catPetPrice);
        setCatBreedPriceInit(catBreedPrice);
        setCatCoatInit(catCoat);
        setCatSexInit(catSex);
        setCatSaleStatus(catSaleStatus);
        setCatIsNeuterInit(catIsNeuter);
        setCatAdvTextInit(catAdvText);
    }

    const handlerClickEditButtonPhoto = () => {
        setEditModePhoto(true);

        setPhotoIDInit(photoID);
        setPhotoCatInit(photoCat);
        setPhotoPathFileInit(photoPathFile);
        setPhotoDateInit(photoDate);
        setPhotoTitleInit(photoTitle);
        setPhotoDescriptionInit(photoDescription);
        setPhotoIsShowAchivementInit(photoIsShowAchivement);
        setPhotoIsShowPhotoInit(photoIsShowPhoto);
    }

    const handlerClickSaveButtonText = () => {
        //saveNickNameOnTheServer();
        setEditModeText(false);
    }

    const handlerClickSaveButtonPhoto = () => {
        //saveNickNameOnTheServer();
        setEditModePhoto(false);
    }

    const handlerClickCancelButtonText = () => {
        setEditModeText(false);
        
        setCatNursery(catNurseryInit);
        setCatStatus(catStatusInit);
        setCatBreed(catBreedInit);
        setCatName(catNameInit);
        setCatSirID(catSirIDInit);
        setCatSirName(catSirNameInit);
        setCatSirCoat(catSirCoatInit);
        setCatDamID(catDamIDInit);
        setCatDamName(catDamNameInit);
        setCatDamCoat(catDamCoatInit);
        setCatBirthday(catBirthdayInit);
        setCatPetPrice(catPetPriceInit);
        setCatBreedPrice(catBreedPriceInit);
        setCatCoat(catCoatInit);
        setCatSex(catSexInit);
        setCatSaleStatus(catSaleStatusInit);
        setCatIsNeuter(catIsNeuterInit);
        setCatAdvText(catAdvTextInit);

        setServerMessage(null);
        setIsServerMessageVisible(false);
        setError(null);
    }

    const handlerClickCancelButtonPhoto = () => {
        setEditModePhoto(false);
        
        setPhotoID(photoIDInit);
        setPhotoCat(photoCatInit);
        setPhotoPathFile(photoPathFileInit);
        setPhotoDate(photoDateInit);
        setPhotoTitle(photoTitleInit);
        setPhotoDescription(photoDescriptionInit);
        setPhotoIsShowAchivement(photoIsShowAchivementInit);
        setPhotoIsShowPhoto(photoIsShowPhotoInit);

        setServerMessage(null);
        setIsServerMessageVisible(false);
        setError(null);
    }

    const editButtonJSXText: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickEditButtonText} disabled={isEditModeText || isEditModePhoto}>
                <img src={"/edit_button.png"} alt={"E"} />
            </button>
        </>
    
    const editButtonJSXPhoto: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickEditButtonPhoto} 
                disabled={isEditModeText || isEditModePhoto}>

                <img src={"/edit_button.png"} alt={"E"} />
            </button>
        </>
    
    const saveButtonJSXText: JSX.Element = 
        <>
            <button className='escd_button' 
                onClick={handlerClickSaveButtonText} 
                disabled={( !(isEditModeText && !isEditModePhoto ) || loading )}>
                
                {loading ? 
                    <span className="loader"></span> : 
                        <img src={"/save_button.png"} alt={"S"} />
                }
            
            </button>
        </>
    
    const saveButtonJSXPhoto: JSX.Element = 
        <>
            <button className='escd_button' 
                onClick={handlerClickSaveButtonPhoto} 
                disabled={( !(!isEditModeText && isEditModePhoto ) || loading )}>
                
                {loading ? 
                    <span className="loader"></span> : 
                        <img src={"/save_button.png"} alt={"S"} />
                }
            
            </button>
        </>
    
    const cancelButtonJSXText: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickCancelButtonText} 
                disabled={( !(isEditModeText && !isEditModePhoto ) || loading )}>

                <img src={"/cancel_button.png"} alt={"C"} />
            </button>
        </>
    
    const cancelButtonJSXPhoto: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickCancelButtonPhoto} 
                disabled={( !(!isEditModeText && isEditModePhoto ) || loading )}>

                <img src={"/cancel_button.png"} alt={"C"} />
            </button>
        </>
    
    const escdButtonBoxJSXText: JSX.Element = 
        <>
            <div className="escd_button_block">
                {editButtonJSXText}
                {saveButtonJSXText}
                {cancelButtonJSXText}
            </div>
        </>
    
    const escdButtonBoxJSXPhoto: JSX.Element = 
        <>
            <div className="escd_button_block">
                {editButtonJSXPhoto}
                {saveButtonJSXPhoto}
                {cancelButtonJSXPhoto}
            </div>
        </>
    
    const handlerClickDeletePhotoButton = (event: React.MouseEvent<HTMLButtonElement>, index: number ) => {
        event.stopPropagation();
        alert("Delete photo");
    }
    
    const isShowDeleteButton: boolean = (props.callFrom === "MyNursery");

    const deleteButtonComponent: React.FC<DeleteButtonProps> = ({ id, onDelete }) => {
        return (
            <>
                <div className="delete_button_element">
                    <button className='escd_button_delete' 
                        onClick={(event) => onDelete(event, id)} 
                        disabled={false}>

                        <img src={"/delete_record_red_button.png"} alt={"D"} />

                    </button>
                </div>
            </>
        );
    }
    //<img src={"/delete_record_red_button.png"} alt={"D"} />
    
    const imageJSX: JSX.Element =
        <>
            <div className="full_card_image">
                {(isShowDeleteButton) && 
                    deleteButtonComponent({ id: activePhoto, onDelete: handlerClickDeletePhotoButton })}

                <img alt={"ph"} 
                    src={(!catPhotos || catPhotos.length === 0) ? "" : props.catCard.photos[activePhoto].path_file } 
                    className="full_photo"
                />
            
                {
                    (!catPhotos || catPhotos.length === 0) ? <></> : 
                        <div className="full_card_button_bar">
                            <button className="photo_slider_button" 
                                onClick={() => handlerPrevPhoto()}
                            >
                                {(props.catCard.photos.length > 2 && activePhoto > 0) ? "<" : " "}
                            </button>

                            {(!isEditModeText && !isEditModePhoto) && sliderButtonsJSX}
                        
                            <button className="photo_slider_button" onClick={() => handlerNextPhoto()}>
                                {(props.catCard.photos.length > 2) && (activePhoto < props.catCard.photos.length - 1) ? ">" : " "}
                            </button>
                        </div>
                }

                <TelephoneNumberHiden telNum={props.catCard.phone_number || ''} 
                    telNumHiden={"+Х-ХXX-XXX-XX-XX"} 
                    className={"full_card_telephone_number"} />
            </div>
        </>
//{(isShowDeleteButton) && deleteButtonComponent({ id: activePhoto, onDelete: handlerClickDeletePhotoButton })}

    const cardTextBlockJSX: JSX.Element = 
        <>
            {(props.callFrom === "MyNursery") && escdButtonBoxJSXText}
            <table className="styled-table">
                <tbody>
                    {cardText}
                </tbody>
            </table>
    
            {(props.callFrom === "MyNursery") && escdButtonBoxJSXPhoto}
            <table className="styled-table">
                <tbody>

                    {(props.callFrom === "MyNursery") ? cardPhotoText : 
                                        (isPhotoComment(dataPhoto)) && cardPhotoText}
                
                </tbody>
            </table>
        </>
        //{(isPhotoComment(dataPhoto)) && cardPhotoText}
    

    const handlerClickAppendButton = () => {
        if (props.callFrom === "MyNursery") {
            //createNurseryOnTheServer();
        }
    }

    const appendButtonJSX: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickAppendButton} 
                disabled={isEditModeText || isEditModePhoto}>
                
                <img src={"/append_button.png"} alt={"D"} />
            </button>
        </>
    
    const appendButtonBlockJSX: JSX.Element = 
        <>
            <div className="append_block">
                {appendButtonJSX}
            </div>
        </>

    const isShowAppendButton: boolean = (props.callFrom === "MyNursery");

    return (
        <>
            <div className="full_card">
                {isShowAppendButton && appendButtonBlockJSX}
                {imageJSX}
                
                <div className="full_card_text">
                    {cardTextBlockJSX}
                </div>
            </div>
        </>
    );
}

/*

 

*/