import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import setAuthenticatedMode from "../redux/authMode";
import { setUserLogin, setUserLogOut } from "../redux/authUser";
import { NavLink } from "react-router-dom";
import { User } from "./CommonData/DataTypes";

export type Menu = {
    id: number,
    name: string,
    url: string,
    isVisible: boolean,
  }

export default function Login(): JSX.Element {
    const dispatch = useDispatch();
    const { userNic, userIsAuthenticated } = useSelector((state: any) => state.user);

    const loginMenuArr: Menu[] = [
        { isVisible: false, id: 1, url: "/tech_support", name: "Техподдержка",},
        { isVisible: false, id: 2, url: "/nopage", name: "Сообщения",},
        { isVisible: false, id: 3, url: "/nopage", name: "Мои питомцы",},
        { isVisible: false, id: 4, url: "/my_announcements", name: "Мои объявления",},
        { isVisible: true, id: 5, url: "/my_nursery", name: "Мои питомники",},
        { isVisible: false, id: 6, url: "/nopage", name: "Финансы",},
        { isVisible: false, id: 7, url: "/nopage", name: "Избранное",},
        { isVisible: true, id: 8, url: "/my_profile", name: "Профиль",},
        { isVisible: true, id: 9, url: "", name: "Выход",},
      ];

    const menuRef = useRef<HTMLDivElement | null>(null);

    const [showLoginMenu, setShowLoginMenu] = useState(false);

    useEffect(() => {
        // Функция, которая закрывает меню при клике за его пределами
        const handleClickOutside = (event:any) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setShowLoginMenu(false);
            }
          };
        // Добавляем обработчик события
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          // Удаляем обработчик события при размонтировании компонента
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    const handlerClickLoginMenuItem = (id: number) => {
        var user: User = { 
            userID: 0,
            userNic: "",
            userLogin: "",
            userToken: "",
            userIsAuthenticated: false,
            userPhoneNumber: '',
        };

        if (id===9) {
            localStorage.removeItem('authToken');
            localStorage.removeItem("nicName");
            localStorage.removeItem("userLogin");
            localStorage.removeItem("userID");
            localStorage.removeItem("userPhoneNumber");

            setUserLogin (dispatch) (user);

            setUserLogOut (dispatch) ()
        } 
        setShowLoginMenu(false);
    }

    const handlerClickComeIn = () => {
        setAuthenticatedMode (dispatch) (true);
    }

    const loginMenu = loginMenuArr.map((item: Menu) => 
        (item.isVisible) ? 
            <div className="login_menu_item">
                <NavLink key={item.id} 
                    className="login_menu_item_nav" 
                    onClick={() => handlerClickLoginMenuItem(item.id)}
                    to={item.url}>
            
                    {item.name}

                </NavLink>
            </div> : <></>);

    const nicNameJSX: JSX.Element = 
        <>
            <button className={(showLoginMenu) ? "login_button_open" : "login_button"} 
                onClick={() => setShowLoginMenu(!showLoginMenu)}>
                
                <div className="user_nic_button">
                    <div className="user_nic">
                        <div className="user_nic_avatar">
                            <img src={"/avatar_noname.png"} alt={"noname"} />
                        </div>
                        <div className="user_nic_name">
                            {userNic}
                        </div>
                    </div>
                </div>
            </button>
            <div className="login_menu">
                {(showLoginMenu) && (loginMenu)}
            </div>
        </>

    const loginButtonJSX: JSX.Element = 
        <>
            <button className="login_button"
                onClick={handlerClickComeIn}>
                    Войти
            </button>
        </>

    return (
        <>
        <div className="section_login" ref={menuRef}>
            { (userIsAuthenticated) ? nicNameJSX : loginButtonJSX }
        </div>
        </>
    );
}

/*
    const loginMenu = loginMenuArr.map((item:Menu) =>
        <div key={item.id} className="login_menu_item" onClick={() => handlerClickLoginMenuItem(item.id)}>
            {item.name}
        </div>
    );


else {
            const url:string = getURL(id);
            document.location.href = url;
        }
*/